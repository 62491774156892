var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"scrollable pa-8",staticStyle:{"min-height":"50vh","max-height":"90vh"},style:({ width: _vm.cardWidth }),attrs:{"flat":""}},[_c('v-btn',{staticClass:"mt-2 headerButton",staticStyle:{"position":"absolute","right":"0%","top":"0%"},attrs:{"text":"","id":"closeButton"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-form',{ref:"jdForm"},[_c('v-container',[_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0 mb-4"},[_c('h2',{staticClass:"encodeFont"},[_vm._v("Stellenanzeige")])])],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0"},[_c('v-combobox',{attrs:{"outlined":"","label":'Firmen*',"multiple":true,"items":_vm.isEditor ? _vm.allCompaniesList : _vm.companies,"return-object":"","item-value":"id","chips":!_vm.isEditor,"no-filter":true,"rules":[
                !_vm.isEditor ? _vm.requiredArray : _vm.required,
                _vm.companyValidRule,
                _vm.sameCompanyOwnerRule ],"search-input":_vm.searchString},on:{"update:searchInput":function($event){_vm.searchString=$event},"update:search-input":function($event){_vm.searchString=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
return [_c('v-chip',_vm._b({attrs:{"close":""},on:{"click:close":function($event){return _vm.removeCompany(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.Contacts && item.Contacts.length > 0)?_c('small',{staticClass:"ml-2"},[_vm._v(_vm._s(item.Contacts[0].first_name)+" "+_vm._s(item.Contacts[0].last_name))]):_vm._e()]}}]),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0"},[_c('v-text-field',{attrs:{"outlined":"","label":"Titel*","rules":[_vm.required, _vm.max100]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-select',{attrs:{"outlined":"","label":"Art der Stelle*","items":_vm.jobTypes,"return-object":"","item-text":"name","rules":[_vm.required]},model:{value:(_vm.jobType),callback:function ($$v) {_vm.jobType=$$v},expression:"jobType"}})],1),(_vm.jobType.name == 'Praktikum')?_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 8}},[_c('v-combobox',{attrs:{"outlined":"","label":"Berufsbezeichnung*","items":_vm.jobNames,"return-object":"","item-text":"name","rules":[_vm.required, _vm.jobNameValidRule]},model:{value:(_vm.jobName),callback:function ($$v) {_vm.jobName=$$v},expression:"jobName"}})],1):_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 11 : 7}},[_c('v-combobox',{attrs:{"outlined":"","label":"Berufsbezeichnung*","items":_vm.allowedJobNamesApprenticeship,"return-object":"","item-text":"name","rules":[_vm.required, _vm.jobNameValidRule],"no-data-text":'Dem Unternehmen wurden keine Ausbildungsberufe zugewiesen.'},model:{value:(_vm.jobName),callback:function ($$v) {_vm.jobName=$$v},expression:"jobName"}})],1),(_vm.jobType.name == 'Ausbildung')?_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","tabindex":"0"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline ")])]}}],null,false,2416239787)},[_c('span',[_vm._v("Sie dürfen nur in den hier gelisteten Berufen Ausbildungen auf unserer Plattform anbieten. Ihre Stellenanzeigen für Ausbildungen werden dafür als verifiziert markiert. "),_c('a',{attrs:{"href":"https://hallo-beruf.de/#/kontakt"}},[_vm._v("Kontaktieren Sie uns")]),_vm._v(", um weitere Berufe hinzuzufügen.")])])],1):_vm._e()],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":12}},[_c('v-combobox',{attrs:{"outlined":"","label":"Branche*","items":_vm.professions,"return-object":"","item-text":"name","rules":[_vm.required, _vm.validObject]},model:{value:(_vm.profession),callback:function ($$v) {_vm.profession=$$v},expression:"profession"}})],1)],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-menu',{ref:"startDateMenu",staticClass:"py-0",attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getReadableDate(_vm.startDate),"label":"Beginn der Stelle","readonly":"","prepend-inner-icon":"mdi-calendar","outlined":"","rules":[],"id":"startDateTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"de-de","first-day-of-week":1},on:{"click:date":function($event){_vm.startDateMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-menu',{ref:"endDateMenu",staticClass:"py-0",attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getReadableDate(_vm.endDate),"label":"Ende der Stelle","readonly":"","prepend-inner-icon":"mdi-calendar","outlined":"","rules":[],"id":"endDateTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"de-de","first-day-of-week":1},on:{"click:date":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-menu',{ref:"availStartDateMenu",staticClass:"py-0",attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getReadableDate(_vm.availStartDate),"label":"Stelle sichtbar ab","readonly":"","prepend-inner-icon":"mdi-calendar","outlined":"","rules":[],"id":"availStartDateTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.availStartDateMenu),callback:function ($$v) {_vm.availStartDateMenu=$$v},expression:"availStartDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"de-de","first-day-of-week":1},on:{"click:date":function($event){_vm.availStartDateMenu = false}},model:{value:(_vm.availStartDate),callback:function ($$v) {_vm.availStartDate=$$v},expression:"availStartDate"}})],1)],1),_c('v-col',{staticClass:"py-0 ma-0",attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-menu',{ref:"availEndDateMenu",staticClass:"py-0",attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.getReadableDate(_vm.availEndDate),"label":"Stelle sichtbar bis","readonly":"","prepend-inner-icon":"mdi-calendar","outlined":"","rules":[],"id":"availEndDateTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.availEndDateMenu),callback:function ($$v) {_vm.availEndDateMenu=$$v},expression:"availEndDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"de-de","first-day-of-week":1},on:{"click:date":function($event){_vm.availEndDateMenu = false}},model:{value:(_vm.availEndDate),callback:function ($$v) {_vm.availEndDate=$$v},expression:"availEndDate"}})],1)],1)],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0"},[_vm._v(" Stellenbeschreibung* ")])],1),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0",attrs:{"tabindex":"0","id":"editorCol"},on:{"focus":function($event){_vm.closeAllDatePickers();
              _vm.focusEditor();}}},[_c('vue-editor',{ref:"vue2Editor",staticClass:"encodeFont focusable",class:{
                redOutline:
                  _vm.htmlDescription.length > 5000 ||
                  (_vm.isJdDetailEmpty && _vm.htmlDescription.length == 0),
              },attrs:{"tabindex":"0","id":"editor","editorToolbar":_vm.customToolbar},model:{value:(_vm.htmlDescription),callback:function ($$v) {_vm.htmlDescription=$$v},expression:"htmlDescription"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('p',{staticClass:"pl-3",staticStyle:{"font-size":"small","color":"red"},style:({
                    display: _vm.htmlDescription.length <= 5000 ? 'none' : 'block',
                  })},[_vm._v(" Maximal 5000 Zeichen! ")])]),_c('p',{staticClass:"pr-3",staticStyle:{"font-size":"small"}},[_vm._v(" "+_vm._s(_vm.htmlDescription.length)+"/5000 ")])])],1)],1),_c('br'),_c('v-row',{staticClass:"py-0 ma-0"},[_c('v-col',{staticClass:"py-0 ma-0"},[_c('v-text-field',{staticClass:"encodeFont",attrs:{"id":"annotationField","outlined":"","label":"Kommentar/ Anmerkung","rules":[_vm.max1000]},model:{value:(_vm.htmlAnnotation),callback:function ($$v) {_vm.htmlAnnotation=$$v},expression:"htmlAnnotation"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"py-0 px-6"},[_c('span',_vm._l((_vm.currentFiles),function(file){return _c('v-chip',{key:file.id,staticClass:"mr-2 mb-2"},[_vm._v(_vm._s(file.name)+" "),_c('span',{on:{"click":function($event){return _vm.removeFiles(file)}}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-close")])],1)])}),1)])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0 pl-5 pr-6",attrs:{"cols":11}},[_c('v-file-input',{attrs:{"label":"Datei","multiple":"","prepend-icon":"mdi-paperclip","outlined":"","chips":"","accept":"application/pdf","rules":[_vm.max3Files, _vm.max3MB]},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('v-col',{staticClass:"pb-8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","tabindex":"1"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Alle hier eingestellten Dateien sind für alle Besucher der Plattform öffentlich zugänglich. ")])])],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-btn',{staticClass:"primaryOnWhiteButton lightOutline pa-1 py-3",attrs:{"block":"","elevation":"0"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-cancel")]),_vm._v(" Abbrechen ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 8}},[_c('v-btn',{staticClass:"primaryButton py-3",attrs:{"block":"","elevation":"0"},on:{"click":function($event){return _vm.saveData()}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-check")]),_vm._v(" Speichern ")],1)],1),_c('v-spacer')],1)],1)],1)],1),_c('div',{staticStyle:{"height":"0px","opacity":"0"},attrs:{"tabindex":"0"},on:{"focus":_vm.resetFocus}},[_vm._v(" Fokus zurück zu \"Schließen\" ")]),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }